export const BACKEND_URL = "https://support.myeverlights.com/api";
export const AUTH_URL = "https://auth.myeverlights.com/api";
export const APPSERVER_URL = "https://appserver-v2.myeverlights.com";
export const TELEMETRYSERVER_URL = "https://telemetry-v2.myeverlights.com";
export const REMOTE_SERVER_URL = "https://remote.myeverlights.com";
export const UPDATE_URL = "https://update.myeverlights.com/";

// if (window.location.hostname === "localhost") {
//     console.log("\nDEVELOPMENT ENVIRONMENT\n\n");
//     // BACKEND_URL = "http://localhost:3000";
//     BACKEND_URL = `https://support.myeverlights.com/api`;
//     AUTH_URL = `https://auth.myeverlights.com/api`;
// }
// else {
//     // BACKEND_URL = `https://${window.location.hostname}/api`;
//     BACKEND_URL = `https://support.myeverlights.com/api`;
//     AUTH_URL = `https://auth.myeverlights.com/api`;
// }
