import axios from "axios";
import store from "store";
import { AUTH_URL } from "../environment";
import jwt from "jsonwebtoken";

axios.defaults.validateStatus = () => true;

interface ITokenResponse {
    token_type: string;
    refresh_token: string;
    access_token: string;
    expires_in: number;
}

function setAxiosBearerToken(token: string) {
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
}

function encryptString(str: string): string {
    const arr = Array.from(str);
    arr.reverse();
    return arr.join("");
}

function decryptString(str: string): string {
    const revArr = Array.from(str);
    revArr.reverse();
    return revArr.join("");
}

function encryptTokens(tokenData: IStashedTokenData): void {
    tokenData.accessToken = encryptString(tokenData.accessToken);
    tokenData.refreshToken = encryptString(tokenData.refreshToken);
}

function decryptTokens(tokenData: IStashedTokenData): void {
    tokenData.accessToken = decryptString(tokenData.accessToken);
    tokenData.refreshToken = decryptString(tokenData.refreshToken);
}

export function hasTokens(): boolean {
    const data = unstashTokenData();
    if (!data) return false;
    setAxiosBearerToken(data.accessToken);
    return true;
}

interface IStashedTokenData {
    accessToken: string;
    refreshToken: string;
    expiresAt: Date;
}

function stashTokenData(data: IStashedTokenData) {
    encryptTokens(data);
    store.set("tokenStash", data);
}

function unstashTokenData(): IStashedTokenData | undefined {
    const data = store.get("tokenStash") as IStashedTokenData | undefined;
    if (!data) return undefined;

    decryptTokens(data);
    data.expiresAt = new Date(data.expiresAt);
    return data;
}

export async function refreshAuthorization(): Promise<boolean> {
    const tokenData = unstashTokenData();
    if (!tokenData) return false;

    const response = await axios.post(`${AUTH_URL}/v1/oauth/token`, {
        grant_type: "refresh_token",
        refresh_token: tokenData.refreshToken,
    });

    if (response.status !== 200) {
        console.log("Refresh authorization failed!");
        return false;
    }

    const tokenResponse = response.data as ITokenResponse;

    if (tokenResponse.token_type !== "bearer") {
        console.log(`Unsupported token type: ${tokenResponse.token_type}`);
        return false;
    }

    setAxiosBearerToken(tokenResponse.access_token);

    stashTokenData({
        refreshToken: tokenResponse.refresh_token,
        accessToken: tokenResponse.access_token,
        expiresAt: new Date(new Date().getTime() + tokenResponse.expires_in * 1000),
    });

    return true;
}

export async function getAuthorization(email: string, password: string): Promise<boolean> {
    const response = await axios.post(
        `${AUTH_URL}/v1/oauth/token`,
        {
            audience: "support auth appserver update remote",
            grant_type: "password",
            username: email,
            password,
        },
        {
            transformRequest: [(data: any, headers: any) => {
                delete headers.common["Authorization"];
                return data;
            }].concat(axios.defaults.transformRequest ? axios.defaults.transformRequest : [])
        }
    );

    if (response.status !== 200) {
        console.log("Refresh authorization failed!");
        return false;
    }

    const tokenResponse = response.data as ITokenResponse;

    if (tokenResponse.token_type !== "bearer") {
        console.log(`Unsupported token type: ${tokenResponse.token_type}`);
        return false;
    }

    setAxiosBearerToken(tokenResponse.access_token);

    stashTokenData({
        refreshToken: tokenResponse.refresh_token,
        accessToken: tokenResponse.access_token,
        expiresAt: new Date(new Date().getTime() + tokenResponse.expires_in * 1000),
    });

    return true;
}

export function clearTokens() {
    console.log("Clearing tokens");
    store.remove("tokenStash");
}

function tokensNeedRefresh(): boolean {
    const data = store.get("tokenStash") as IStashedTokenData | undefined;
    if (!data) return false;

    const expiresAt = new Date(data.expiresAt);

    return expiresAt.getTime() - new Date().getTime() < 60000;
}

let TokenRefreshInterval: NodeJS.Timer | undefined = undefined;
export function initializeTokenManager(onGrantsChanged: (grants: Array<string> | undefined) => void) {
    if (TokenRefreshInterval) return;

    if (tokensNeedRefresh()) {
        refreshAuthorization().then(success => {
            if (success) {
                console.log("Autorenewed tokens")
                onGrantsChanged(getTokenGrants());
            }
            else {
                console.error("Failed to autorenew tokens!");
                clearTokens();
                onGrantsChanged(undefined);
            }
        }).catch(() => {
            console.error("Failed to autorenew tokens!");
            clearTokens();
            onGrantsChanged(undefined);
        })
    }

    TokenRefreshInterval = setInterval(() => {
        if (!tokensNeedRefresh()) return;

        refreshAuthorization().then(success => {
            if (success) {
                console.log("Autorenewed tokens")
                onGrantsChanged(getTokenGrants());
            }
            else {
                console.error("Failed to autorenew tokens!");
                clearTokens();
                onGrantsChanged(undefined);
            }
        }).catch(() => {
            console.error("Failed to autorenew tokens!");
            clearTokens();
            onGrantsChanged(undefined);
        })
    }, 30000); // every 30 secs
}

export function getTokenGrants(): string[] | undefined {
    const tokenData = unstashTokenData();
    if (!tokenData) return undefined;
    const { grants } = jwt.decode(tokenData.accessToken) as { grants: string[] };
    if (!grants || !Array.isArray(grants)) return [];
    return grants;
}

if (!axios.defaults.headers.common["Authorization"]) {
    const data = unstashTokenData();
    if (data) {
        setAxiosBearerToken(data.accessToken);
    }
}
